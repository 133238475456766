import logo from './logo.svg';
import './App.css';
import { Player } from 'video-react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{width:'80%'}}>
          <video autoPlay preload controls  style={{width:'100%', border: '3px solid whitesmoke'}}>
              <source src="//sinterklaas.microos.com/sinterklaas2020.mp4" type="video/mp4"></source>
              <source src="//sinterklaas.microos.com/sinterklaas2020.webm" type="video/webm"></source>
              <source src="//sinterklaas.microos.com/sinterklaas2020.ogg" type="video/ogg"></source>
          </video>
        </div>
      </header>
    </div>
  );
}

export default App;
